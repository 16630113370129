import React from 'react';
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

function MicrosoftLoginButton() {
  return (
    <Button
      onClick={() => window.location.href = 'https://jeger.rms.riaa.com/api/v2/auth/microsoft'}
      variant="contained"
      fullWidth
      sx={{ 
        mt: 2,
        mb: 2,
        backgroundColor: '#2F2F2F',
        '&:hover': { backgroundColor: '#1F1F1F' } 
      }}
    >
      <Icon sx={{ mr: 1 }}>microsoft</Icon>
      Sign in with Microsoft
    </Button>
  );
}

export default MicrosoftLoginButton;