// src/auth/microsoft-callback/index.js
import { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "context";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularProgress from "@mui/material/CircularProgress";

function MicrosoftCallback() {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  
  useEffect(() => {
    const exchangeToken = async () => {
      try {
        // Get the temporary token from URL query
        const urlParams = new URLSearchParams(location.search);
        const tempToken = urlParams.get('state');
        
        if (!tempToken) {
          setError("Invalid authentication response");
          setTimeout(() => navigate("/auth/login"), 3000);
          return;
        }
        
        // Exchange for the real token
        const response = await axios.post('/api/v2/auth/microsoft-exchange', {
          state: tempToken
        });
        
        if (response.data.token) {
          // Set the token in localStorage
          localStorage.setItem('token', response.data.token);
          
          // Update authentication context
          authContext.setIsAuthenticated(true);
          
          // Navigate to dashboard
          navigate("/dashboards/analytics");
        } else {
          setError("Failed to retrieve authentication token");
          setTimeout(() => navigate("/auth/login"), 3000);
        }
      } catch (err) {
        console.error("Microsoft authentication error:", err);
        setError("Authentication failed");
        setTimeout(() => navigate("/auth/login"), 3000);
      }
    };
    
    exchangeToken();
  }, [location, navigate, authContext]);
  
  return (
    <MDBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      {error ? (
        <MDTypography variant="h5" color="error" textAlign="center">
          {error}
          <br />
          <MDTypography variant="body2" color="text">
            Redirecting to login page...
          </MDTypography>
        </MDTypography>
      ) : (
        <>
          <CircularProgress color="info" />
          <MDTypography variant="h5" mt={2}>
            Completing your Microsoft login...
          </MDTypography>
        </>
      )}
    </MDBox>
  );
}

export default MicrosoftCallback;